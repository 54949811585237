<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <PeriodsList />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import PeriodsList from '@/components/Periods/PeriodsList'

export default {
  name: 'PeriodsIndexView',
  components: {
    Layout,
    PeriodsList
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' }
      },
      {
        text: 'Períodos',
        to: { name: 'PeriodsIndex' },
        active: true
      }
    ]
  }
}
</script>
