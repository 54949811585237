<template>
  <div>
    <b-button-toolbar key-nav class="mb-3">
      <b-button variant="primary" size="sm" :to="{ name: 'PeriodRegister' }">
        <span class="fas fa-plus mr-1" /> Cadastrar Período
      </b-button>
    </b-button-toolbar>

    <b-alert v-if="error" show variant="danger">
      <span class="fas fa-exclamation-triangle mr-1" /> {{ error }}
    </b-alert>

    <b-table
      v-else
      ref="periodsList"
      :fields="fields"
      :items="provider"
      striped
      hover
      show-empty
      :busy="isBusy"
    >
      <div slot="empty" class="text-center">Nenhum período cadastrado</div>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="getColumnStyle(field.key)"
        />
      </template>
      <template #cell(time)="{ value: time }">{{ time }} minutos</template>
      <template #cell(price)="{ value: price }">
        R$ {{ price.toFixed(2) }}
      </template>
      <template #cell(store)="{ value: store }">
        {{ store.name }}
      </template>
      <template #cell(status)="{ value: status }">
        <Status :value="status" />
      </template>
      <template #cell(actions)="{ item }">
        <BaseListActions
          :item="item"
          @edit="onEdit"
          @remove="onRemove"
          @updateStatus="onUpdateStatus"
        />
      </template>
    </b-table>

    <b-modal
      id="active-rentals-modal"
      ref="activeRentalsModal"
      title="Aluguéis Ativos Encontrados"
      size="lg"
      hide-footer
      centered
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text-center mb-3" v-html="modalMessage"></div>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click="closeModal">Entendi</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import services from '@/services'
import Status from '@/components/Status'
import BaseListActions from '@/components/BaseListActions'
import withStatusAction from '@/mixins/withStatusAction'
import { parseHTTPError } from '@/helpers/errors'
import { filterActiveRentalsByPeriod } from '@/helpers/rents'

export default {
  name: 'PeriodsList',
  components: {
    Status,
    BaseListActions
  },
  mixins: [withStatusAction(services.periods.updatePeriod)],
  data() {
    return {
      isBusy: false,
      error: null,
      modalMessage: ''
    }
  },
  computed: {
    fields: () => [
      {
        key: 'time',
        label: 'Tempo'
      },
      {
        key: 'price',
        label: 'Preço'
      },
      {
        key: 'store',
        label: 'Loja'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  },
  methods: {
    async provider() {
      this.isBusy = true
      try {
        return await services.periods.fetchAllPeriods()
      } catch (error) {
        this.error = parseHTTPError(error)
        return []
      } finally {
        this.isBusy = false
      }
    },
    onEdit(period) {
      const { id } = period
      this.$router.push({ name: 'PeriodRegister', params: { id } })
    },
    async onRemove(period) {
      const requestRentsByPeriods = await filterActiveRentalsByPeriod(period.id)

      if (requestRentsByPeriods.length > 0) {
        const toyNames = [
          ...new Set(requestRentsByPeriods.map((rent) => rent.toyName))
        ]

        const toyMessages = toyNames.map((name) => `${name}`).join('<br>')

        this.modalMessage = `
          <p>
            Existem aluguéis ativos para este período nos seguintes brinquedos:<br><br>
            ${toyMessages}<br><br>
            Não é possível remover este período enquanto houver aluguéis ativos.
          </p>
        `

        this.$refs.activeRentalsModal.show()
      } else {
        // Não existem aluguéis ativos, proceder com a remoção
        const message = `Deseja realmente remover o período ${period.time}?`
        const result = await this.$bvModal.msgBoxConfirm(message, {
          title: 'Confirmar Remoção',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          centered: true
        })

        if (result) {
          await services.periods.removePeriod(period.id)
          this.$refs.periodsList.refresh()
          this.$root.$toast.success('Período removido com sucesso')
        }
      }
    },
    closeModal() {
      this.$refs.activeRentalsModal.hide()
    },
    async onUpdateStatus(period) {
      await this.toggleStatus(period)
      this.$refs.periodsList.refresh()
    },
    getColumnStyle(field) {
      return { width: field === 'actions' ? '1%' : '' }
    }
  }
}
</script>
